module.exports = `<div id="kalpa-tree-icons" style="position: absolute; left: -10000px; width: 0; height: 0;">
	<svg xmlns="http://www.w3.org/2000/svg">
		<symbol id="icon-generic" viewBox="0 0 12 12">
			<path
				d="M9.7 12H2.3C1 12 0 11 0 9.7V2.3C0 1 1 0 2.3 0h7.5C11 0 12 1 12 2.3v7.5C12 11 11 12 9.7 12zM8 5.3C8 4.6 7.4 4 6.6 4H5.3C4.6 4 4 4.6 4 5.3v1.3C4 7.4 4.6 8 5.3 8h1.3C7.4 8 8 7.4 8 6.6V5.3z" />
		</symbol>
		<symbol id="icon-root" viewBox="0 0 16 16">
			<path d="M9 16V9h7v7H9zM9 0h7v7H9V0zM0 9h7v7H0V9zm0-9h7v7H0V0z" />
		</symbol>
		<symbol id="icon-metric" viewBox="0 0 100 100">
			<path
				d="M50 100C22.4 100 0 77.6 0 50S22.4 0 50 0s50 22.4 50 50-22.4 50-50 50zm0-76.9c-14.8 0-26.9 12.1-26.9 26.9S35.2 76.9 50 76.9 76.9 64.8 76.9 50 64.8 23.1 50 23.1zm0 38.4c-6.4 0-11.5-5.2-11.5-11.5S43.6 38.4 50 38.4s11.5 5.2 11.5 11.5S56.4 61.5 50 61.5z" />
		</symbol>
		<symbol id="icon-perspective" viewBox="0 0 12 11">
			<path d="M12 11H0L5.9 0 12 11z" />
		</symbol>
		<symbol id="icon-objective" viewBox="0 0 12 10">
			<ellipse cx="6" cy="5" rx="6" ry="5" />
		</symbol>
		<symbol id="icon-find">
			<path
				d="m 13.497323,12.433608 c 0.08422,0.978628 -1.417295,1.469942 -1.954282,0.657234 C 10.369384,11.968141 9.2411696,10.799391 8.087323,9.6566079 5.7875518,11.20515 2.2596241,10.270797 1.051352,7.7662065 -0.1027543,5.5922068 0.61269436,2.5743169 2.7539681,1.2885899 4.6322082,0.06461011 7.3623548,0.26924656 8.9286688,1.9183502 c 1.5121032,1.4872335 1.9726552,4.001359 0.8885116,5.8618536 -0.2008407,0.7179057 0.6439246,1.0286413 1.0395966,1.4627471 0.79551,0.9178591 1.752248,1.6943191 2.51171,2.6495761 0.08347,0.167322 0.129284,0.353872 0.128836,0.541081 z m -8.047,-9.7420001 C 3.5991559,2.6059751 2.107634,4.7815577 2.8684439,6.4772182 3.4629388,8.0470383 5.6500695,8.7859958 7.0177529,7.7488936 8.3664062,6.8424394 8.6521549,4.6587499 7.425963,3.52989 6.9118115,3.0069548 6.1883031,2.6803036 5.450323,2.6916079 z" />
		</symbol>
		<symbol id="icon-collapsed" viewBox="0 0 9 6">
			<path
				d="M4.4 6c-.2 0-.5-.1-.9-.5L.3 1.8C-.1 1.4-.1.7.3.3s1.1-.4 1.5 0l2.7 3.2L7.1.4C7.5 0 8.2 0 8.6.4c.4.4.4 1.1 0 1.5L5.4 5.5c-.3.4-.8.5-1 .5z" />
		</symbol>
	</svg>
</div>`
